import React, { ReactNode } from 'react'
import { Link, LinkProps } from '@chakra-ui/react'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { DOPPEL_LINK, FONT_SIZE_MEDIUM } from '@/utils/style'
import { prepareUrl } from '@/utils/domain_utils'
import { useGcsSignedUrl } from '@/hooks/cloud_storage'

type Props = {
  href: string
  name?: ReactNode
  shouldSign?: boolean
} & LinkProps

/**
 * Renders a link to a website outside of app.doppel.com.
 *
 * Intended as a simple replacement for DoppelLink in this case.
 */
const ExternalLink: React.FC<Props> = ({ href, name, shouldSign, ...rest }) => {
  const { signedUrl, loading: signedUrlLoading } = useGcsSignedUrl({
    url: shouldSign ? href : null,
  })

  const linkHref = shouldSign && !signedUrlLoading ? signedUrl : prepareUrl(href)

  return (
    <Link
      color={DOPPEL_LINK}
      fontSize={FONT_SIZE_MEDIUM}
      href={linkHref}
      isExternal
      {...rest}
    >
      {/* workaround: render the icon as a link, since URL is shown in dropdown */}

      {name ? name : <ExternalLinkIcon ml={2} />}
    </Link>
  )
}

export default ExternalLink
