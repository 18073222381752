import { Button } from '@chakra-ui/react'
import {
  DOPPEL_INTERNAL_PURPLE,
  DOPPEL_WHITE,
  DOPPEL_BUTTON_GREY,
  DOPPEL_OFFICIAL_BLUE,
  FONT_SIZE_MEDIUM,
  DOPPEL_INTERNAL_PURPLE_SHADE,
  DOPPEL_CYBER_BLUE_SHADE,
  DOPPEL_BUTTON_GREY_SHADE,
} from '../../utils/style'

const DoppelPrimaryButton = ({
  children,
  isInternal = false,
  isPrimaryColor = false,
  ...props
}) => {
  const bgColor = (() => {
    if (isInternal) return DOPPEL_INTERNAL_PURPLE
    else if (isPrimaryColor) return DOPPEL_OFFICIAL_BLUE
    return DOPPEL_BUTTON_GREY
  })()

  const hoverColor = (() => {
    if (isInternal) return DOPPEL_INTERNAL_PURPLE_SHADE
    else if (isPrimaryColor) return DOPPEL_CYBER_BLUE_SHADE
    return DOPPEL_BUTTON_GREY_SHADE
  })()
  return (
    <Button
      _hover={{ background: hoverColor }}
      as={Button}
      bgColor={bgColor}
      fontSize={FONT_SIZE_MEDIUM}
      height={9}
      minWidth="80px"
      textColor={DOPPEL_WHITE}
      {...props}
    >
      {children}
    </Button>
  )
}

export default DoppelPrimaryButton
