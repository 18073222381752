import { useGcsSignedUrl } from '@/hooks/cloud_storage'
import { Image, Box } from '@chakra-ui/react'

export enum TablePreviewSize {
  REGULAR,
  LARGE,
}

export const previewSizeToPixels = {
  [TablePreviewSize.REGULAR]: 70,
  [TablePreviewSize.LARGE]: 85,
}

export const previewSizeToWidth = {
  [TablePreviewSize.LARGE]: 70,
}

const TablePreview = ({
  src,
  previewSize = TablePreviewSize.REGULAR,
  shouldSign = false,
  width = undefined,
}) => {
  const { signedUrl } = useGcsSignedUrl({ url: src })
  if (!src) return <></>

  return (
    <Box width={width || previewSizeToPixels[previewSize]}>
      <Image
        boxShadow={'l'}
        boxSize={previewSizeToPixels[previewSize]}
        rounded={'md'}
        // objectFit="cover"
        src={shouldSign ? signedUrl : src}
        width={width}
      />
    </Box>
  )
}

export default TablePreview
