import ExternalLink from '@/components/report_detail/external_link'
import { useGetGcsObjectQuery } from '@/generated/graphql'
import { Spinner } from '@chakra-ui/react'

export function SignedGCSObjectLink({
  fileName,
  objectNamePrefix,
  bucketName,
  includeObject,
  linkDisplayName = fileName,
  fontSize = undefined,
  spinner = false,
}) {
  const { data, loading, error } = useGetGcsObjectQuery({
    fetchPolicy: 'cache-first',
    variables: {
      object_name: `${objectNamePrefix}${fileName}`,
      bucket_name: bucketName,
      include_object: includeObject,
    },
  })

  const renderContent = () => {
    if (loading) {
      return spinner ? <Spinner /> : <>{fileName}</>
    }

    if (data) {
      const signedUrl =
        data?.get_gcs_object?.object_info?.object_versions[0]?.signed_url || ''

      return (
        <ExternalLink fontSize={fontSize} href={signedUrl} name={linkDisplayName} />
      )
    }

    return <>{fileName}</>
  }

  return renderContent()
}
